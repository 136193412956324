/* eslint-disable import/order */
/* eslint-disable import/first */
import 'normalize.css'
import React from 'react'
import 'react-app-polyfill/ie11'
import * as ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import './styles/app.scss'
import './styles/tailwind.css'
import debug from './utils/debug'

const appStage = process.env.REACT_APP_STAGE || 'development'
// if (appStage === 'development') {
//   import('./wdyu').then(() => {
//     debug('starting why did you re render')
//   })
// }

// tslint:disable-next-line:ordered-imports

// tslint:disable-next-line:ordered-imports

if (appStage !== 'development') {
  debug('GA started..')
  ReactGA.initialize('UA-162101683-1', {
    debug: appStage !== 'production',
    gaOptions: {
      alwaysSendReferrer: true,
    },
  })
}

ReactDOM.render(<Routes />, document.getElementById('app'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({})
