const config = {
  dev: {
    apiEndpoint: '',
  },
  stage: {
    apiEndpoint: '',
  },
  prod: {
    apiEndpoint: '',
  },
  common: {
    apiEndpoint: '',
    site: process.env.REACT_APP_SITE,
  },
}

const NODE_ENV = process.env.REACT_APP_STAGE || 'dev'

const runtimeConfig = { ...config.common, ...config[NODE_ENV] }

if (process.env.REACT_APP_API_URL) {
  runtimeConfig.apiEndpoint = process.env.REACT_APP_API_URL
}

export default runtimeConfig
