import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { asyncComponent } from 'react-async-component'
import ReactGA from 'react-ga'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import LoadingSpinner from './components/loadingSpinner'
import history from './history'
import debug from './utils/debug'
import isFullScreenMode from './utils/isFullScreenMode'

const asyncTvcPage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "tvc" */ './pages/tvc'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'TvcPage',
})
const asyncMvPage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "mv" */ './pages/mv'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'MvPage',
})
const asyncFilmsPage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "films" */ './pages/films'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'FilmsPage',
})
const asyncExhibitionAndTheaterPage = asyncComponent({
  resolve: () =>
    import(/* webpackChunkName: "exhibition" */ './pages/exhibitionAndTheater'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'ExhibitionAndTheaterPage',
})
const asyncPhotoStoriesPage = asyncComponent({
  resolve: () =>
    import(/* webpackChunkName: "photo-stories" */ './pages/photoStories'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'PhotoStoriesPage',
})

/**
 * Full screen async components
 */
const asyncHomePage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "home" */ './pages/home'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'HomePage',
})
const asyncBeliefPage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "belief" */ './pages/belief'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'BeliefPage',
})
const asyncArtistsPage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "artists" */ './pages/artists'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'ArtistsPage',
})
const asyncContactPage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "contact" */ './pages/contact'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'ContactPage',
})
const asyncShowcasePage = asyncComponent({
  resolve: () => import(/* webpackChunkName: "showcase" */ './pages/showcase'),
  LoadingComponent: () => <LoadingSpinner />,
  name: 'ShowcasePage',
})

const nonFullScreenRoutes = [
  {
    path: '/',
    component: asyncHomePage,
    exact: true,
  },
  {
    path: '/belief',
    component: asyncBeliefPage,
    exact: true,
  },
  {
    path: '/artists',
    component: asyncArtistsPage,
    exact: true,
  },
  {
    path: '/contact',
    component: asyncContactPage,
    exact: true,
  },
  {
    path: '/showcase',
    component: asyncShowcasePage,
    exact: true,
  },
]

const fullScreenRoutes = [
  {
    path: '/adverts',
    component: asyncTvcPage,
    exact: false,
  },
  {
    path: '/advertisement',
    component: asyncTvcPage,
    exact: false,
  },
  {
    path: '/mvs',
    component: asyncMvPage,
    exact: false,
  },
  {
    path: '/music-videos',
    component: asyncMvPage,
    exact: false,
  },
  {
    path: '/films',
    component: asyncFilmsPage,
    exact: false,
  },
  {
    path: '/exhibitions',
    component: asyncExhibitionAndTheaterPage,
    exact: false,
  },
  {
    path: '/photo-stories',
    component: asyncPhotoStoriesPage,
    exact: false,
  },
]

const RedirectToMain = _ => {
  return <Redirect to="/" />
}

const Routes: FunctionComponent<{}> = () => {
  useEffect(() => {
    if (isFullScreenMode()) {
      ReactGA.modalview(window.location.pathname)
    } else {
      ReactGA.pageview(window.location.pathname)
    }
  }, [])

  useEffect(() => {
    if (typeof window.onerror === 'object') {
      window.onerror = (err, url, line) => {
        debug(`sending error event : ${JSON.stringify(err)}`)
        ReactGA.exception({
          description: `${line} ${err}`,
          fatal: true,
        })
      }
    }
  }, [])

  const renderRoute = useCallback(route => {
    return (
      <Route
        path={route.path}
        key={`page_${route.path}`}
        component={route.component}
        exact={route.exact}
      />
    )
  }, [])

  const availableRoutes = () => {
    if (isFullScreenMode()) {
      return fullScreenRoutes
    }
    // debug(nonFullScreenRoutes.concat(fullScreenRoutes))
    return nonFullScreenRoutes.concat(fullScreenRoutes)
  }

  return (
    <Router history={history}>
      <Switch>
        {availableRoutes().map(r => renderRoute(r))}
        {!isFullScreenMode() ? <Route component={RedirectToMain} /> : null}
      </Switch>
    </Router>
  )
}
export default Routes
