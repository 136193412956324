import React from 'react'

import './loadingSpinner.scss'

const LoadingSpinner = () => {
  return (
    <div className="loadingContainer">
      <div className="loadingIcon">
        <div className="square-holder">
          <div className="square" />
        </div>
      </div>
    </div>
  )
}

export default LoadingSpinner
